/* eslint-disable */
const localization = {
    // This is the array of lexemes found in the app
    lexemes: [
      "Go back",
      "Download on the App Store",
      "Get it on Google Play",
      "Carousel",
      "Slide {{number}}",
      "Next slide",
      "Previous slide",
      "Slide",
      "Slide {{number}} of {{total}}",
      "Do not sell or share my personal information",
      "Do not sell or share my personal information",
      "Terminate contracts here",
      "Bumble homepage",
      "Close menu",
      "Open menu",
      "Language selection",
      "Footer",
      "Accessibility at Bumble",
      "Apple and the Apple logo are trademarks of Apple Inc.",
      "Bumble BFF",
      "Bumble Bizz",
      "Bumble Date",
      "Careers",
      "Events",
      "Google Play is a trademark of Google LLC.",
      "Guidelines",
      "Investors",
      "Manage Cookies",
      "Manage Cookies",
      "Modern Slavery Act Statement",
      "Notice At Collection",
      "Privacy Policy",
      "Seasonal Dating Guides",
      "Terms & Conditions",
      "The app",
      "Washington Consumer Health Data Privacy Policy",
      "About",
      "Ambassadors",
      "The Buzz",
      "The Shop",
      "Main",
      "Skip to main content",
      "Bumble logo",
      "Here, kindness is sexy, you being you is perfect, and women come first. Always.",
      "The Home Of Make The First Move™",
      "Healthy relationships are central to living a positive, productive life. Bumble is designed to help you feel empowered while you make those connections, whether you’re dating, looking for friends, or growing your professional network.",
      "Why Bumble Matters",
      "We champion integrity, kindness, equality, confidence, and respect during all stages of any relationship. We don’t just welcome, we celebrate every orientation—whether you’re here to find love or simply find fun.",
      "What Bumble Values",
      "On Bumble, women set the tone by making the first move or by setting an Opening Move for matches to reply to. Shifting old-fashioned power dynamics and encouraging equality from the start.",
      "How Bumble Works",
      "About Us",
      "Download now",
      "It’s better on the app.",
      "Features designed to make online dating actually, you know, enjoyable",
      "February 12, 2024",
      "How to increase your emotional intimacy in your relationship",
      "Jan 25, 2024",
      "How to use Bumble when you have never used a dating app before",
      "February 5, 2024",
      "Deception Detector™",
      "All the dating news that’s fit to print",
      "Browse <b>For You</b> to get curated profiles based on your preferences and previous matches.",
      "Curated for you",
      "Put dating on hold with <b>Snooze Mode</b>. When you’re ready to date again, you know where we are.",
      "Take a break",
      "Use <b>video calls</b> and <b>audio chats</b> to get as close to IRL without leaving your house.",
      "Sound it out",
      "View more",
      "Choose <b>Opening Moves</b> to invite your match to start the chat, so you don’t have to.",
      "Do less, date more",
      "Find your duty-free snack and match anywhere in the world with <b>Travel Mode</b>.",
      "Take us away",
      "Profile passed the vibe check? Let them know with a <b>SuperSwipe</b>.",
      "Get their attention",
      "Throw some <b>Compliments</b> their way so you stand out from the crowd.",
      "Make them blush",
      "Use <b>Incognito Mode</b> to hide your profile from your boss, relatives, or exes.",
      "Go undercover",
      "Date the way you want to",
      "A Few Months Later, She Met One of Her Bridesmaids There, Too",
      "Hannah Met Her Now-Husband on Bumble.",
      "Before building their tiny house together Carène and You-Liang met on Bumble, where You-Liang was drawn in by Carène’s travel pics.",
      "Carène and You-Liang",
      "Brian and Kaci Were Stationed Overseas Together—But They Met on Bumble",
      "Brian and Kaci",
      "If it worked for them, it could work for you",
      "Make the next move",
      "See what’s new",
      "You deserve better, so we’ve designed great ways for you to date more and stress less.",
      "Bring your bar back up to where it belongs",
      "About Bumble",
      "We’re the only app that makes dating better by putting women’s experiences first. Because when things are better for women, they’re better for everyone.",
      "Make the first move™",
      "Find someone you actually want to date, then go ahead and Make the First Move. Make genuine connections, vibe over what you both love, and most importantly, have fun.",
      "Meet someone with Bumble Date",
      "Find friends with work benefits. Connect with other business professionals to find your next job, a mentor or even a whole new career.",
      "Make career moves with Bumble Bizz",
      "Meet someone like you. Or unlike you. Make new friends and find new things to do together—whether you’re home or away.",
      "Find new friends on Bumble For Friends",
      "We’re not just for dating",
      "Bumble is the dating app where women Make the First Move. It’s the app that changed the way people date, create meaningful relationships, and network. Download now.",
      "Bumble on {{social_network}}",
      "Gender Pay Gap",
      "Modern Slavery Statement",
      "Terms and Conditions",
      "About",
      "Life",
      "We’re sorry, but we can’t find the page you were looking for.",
      "Contact us",
      "Sign In",
      "Oops, something went wrong",
      "(opens in new window)",
      "FAQ",
      "Cookie Policy",
      "Bumble - The Blog",
      "Careers",
      "Chat",
      "Cities",
      "Manage cookies",
      "Copyright © 2006 – present. Bumble. All rights reserved.",
      "Dating Confidence Report",
      "Apple and the Apple logo are trademarks of Apple Inc.{{__EOL__}}Google Play is a trademark of Google LLC.",
      "Download",
      "Facebook",
      "Follow us",
      "Help Center",
      "Instagram",
      "Language",
      "LinkedIn",
      "Bumble is part of Bumble Inc.",
      "Privacy policy",
      "Safety Center",
      "Sitemap",
      "Modern Slavery Act statement",
      "Terms and conditions",
      "Twitter"
],

    // These are the common words found in the lexemes of the app
    common: {
      "day": {
            "plural": [
                  "days"
            ],
            "singular": [
                  "day"
            ]
      },
      "hour": {
            "plural": [
                  "hours"
            ],
            "singular": [
                  "hour"
            ]
      },
      "minute": {
            "plural": [
                  "minutes"
            ],
            "singular": [
                  "minute"
            ]
      },
      "month": {
            "plural": [
                  "months"
            ],
            "singular": [
                  "month"
            ]
      },
      "second": {
            "plural": [
                  "seconds"
            ],
            "singular": [
                  "second"
            ]
      },
      "year": {
            "plural": [
                  "years"
            ],
            "singular": [
                  "year"
            ]
      }
}
};

// In dev mode we extend lexemes to include the indexMap as well, in production it remains as an array
if (__DEV__) {
    // This is a map of lexeme ids to their index in the array, it's only needed during development
    const indexMap = require('./index-map').default;

    localization.indexMap = indexMap;

    Object.entries(localization.indexMap).forEach(([indexKey, indexValue]) => {
        localization.lexemes[indexKey] = localization.lexemes[indexValue];
    });
}

export default localization;
