/* eslint-disable */
export default {
      "onboarding@bumble.incognito.dismiss_warning.cta.cancel": 0,
      "bumblesite@apps-links.app-store.a11y.label": 1,
      "bumblesite@apps-links.google-play.a11y.label": 2,
      "bumblesite@carousel.a11y.description": 3,
      "bumblesite@carousel.navigation.dot.a11y.label": 4,
      "bumblesite@carousel.navigation.next.a11y.label": 5,
      "bumblesite@carousel.navigation.prev.a11y.label": 6,
      "bumblesite@carousel.slide.a11y.description": 7,
      "bumblesite@carousel.slide.a11y.label": 8,
      "bumblesite@ccpa.donotsell.cta": 9,
      "bumblesite@ccpa_cta.title": 10,
      "bumblesite@german_cancelation_policy_flow.form.cta": 11,
      "bumblesite@header.logo.a11y.label": 12,
      "bumblesite@header.navigation.menu.close.a11y.label": 13,
      "bumblesite@header.navigation.menu.open.a11y.label": 14,
      "bumblesite@language-selector.a11y.label": 15,
      "bumblesite@layout.footer.a11y.label": 16,
      "bumblesite@LAYOUT.FOOTER.ACCESSIBILITY_STATEMENT": 17,
      "bumblesite@LAYOUT.FOOTER.Apple.legal_text": 18,
      "bumblesite@LAYOUT.FOOTER.BUMBLE_BFF": 19,
      "bumblesite@LAYOUT.FOOTER.BUMBLE_BIZZ": 20,
      "bumblesite@LAYOUT.FOOTER.BUMBLE_DATE": 21,
      "bumblesite@LAYOUT.FOOTER.CAREERS": 22,
      "bumblesite@LAYOUT.FOOTER.EVENTS": 23,
      "bumblesite@LAYOUT.FOOTER.Google_play.legal_text": 24,
      "bumblesite@LAYOUT.FOOTER.GUIDELINES": 25,
      "bumblesite@LAYOUT.FOOTER.investors": 26,
      "bumblesite@LAYOUT.FOOTER.MANAGE_COOKIES": 27,
      "bumblesite@LAYOUT.FOOTER.MANAGE_COOKIES.label": 28,
      "bumblesite@LAYOUT.FOOTER.msa": 29,
      "bumblesite@LAYOUT.FOOTER.NoticeAtCollection": 30,
      "bumblesite@LAYOUT.FOOTER.PRIVACY_POLICY": 31,
      "bumblesite@LAYOUT.FOOTER.SEASONAL_DATING_GUIDES.label": 32,
      "bumblesite@LAYOUT.FOOTER.Terms": 33,
      "bumblesite@LAYOUT.FOOTER.THEAPP": 34,
      "bumblesite@LAYOUT.FOOTER.Washington_Consumer_Health_Data_Privacy_Policy.label": 35,
      "bumblesite@LAYOUT.HEADER.ABOUT": 36,
      "bumblesite@LAYOUT.HEADER.AMBASSADORS": 37,
      "bumblesite@LAYOUT.HEADER.BEEHIVE": 38,
      "bumblesite@LAYOUT.HEADER.THE_SHOP": 39,
      "bumblesite@layout.main.a11y.label": 40,
      "bumblesite@layout.skip-to-main-content": 41,
      "bumblesite@logo.a11y.label": 42,
      "bumblesite@rebrand.2024.page.about.one.block.text": 43,
      "bumblesite@rebrand.2024.page.about.one.block.title": 44,
      "bumblesite@rebrand.2024.page.about.two.block.item.one.text": 45,
      "bumblesite@rebrand.2024.page.about.two.block.item.one.title": 46,
      "bumblesite@rebrand.2024.page.about.two.block.item.three.text": 47,
      "bumblesite@rebrand.2024.page.about.two.block.item.three.title": 48,
      "bumblesite@rebrand.2024.page.about.two.block.item.two.text": 49,
      "bumblesite@rebrand.2024.page.about.two.block.item.two.title": 50,
      "bumblesite@rebrand.2024.page.about.two.block.title": 51,
      "bumblesite@rebrand.2024.page.date.one.block.cta-action": 52,
      "bumblesite@rebrand.2024.page.date.one.block.text": 53,
      "bumblesite@rebrand.2024.page.date.one.block.title": 54,
      "bumblesite@rebrand.2024.page.date.three.block.item.one.text": 55,
      "bumblesite@rebrand.2024.page.date.three.block.item.one.title": 56,
      "bumblesite@rebrand.2024.page.date.three.block.item.three.text": 57,
      "bumblesite@rebrand.2024.page.date.three.block.item.three.title": 58,
      "bumblesite@rebrand.2024.page.date.three.block.item.two.text": 59,
      "bumblesite@rebrand.2024.page.date.three.block.item.two.title": 60,
      "bumblesite@rebrand.2024.page.date.three.block.title": 61,
      "bumblesite@rebrand.2024.page.date.two.block.item.eight.text": 62,
      "bumblesite@rebrand.2024.page.date.two.block.item.eight.title": 63,
      "bumblesite@rebrand.2024.page.date.two.block.item.five.text": 64,
      "bumblesite@rebrand.2024.page.date.two.block.item.five.title": 65,
      "bumblesite@rebrand.2024.page.date.two.block.item.four.text": 66,
      "bumblesite@rebrand.2024.page.date.two.block.item.four.title": 67,
      "bumblesite@rebrand.2024.page.date.two.block.item.one.showmore.action": 68,
      "bumblesite@rebrand.2024.page.date.two.block.item.one.text": 69,
      "bumblesite@rebrand.2024.page.date.two.block.item.one.title": 70,
      "bumblesite@rebrand.2024.page.date.two.block.item.seven.text": 71,
      "bumblesite@rebrand.2024.page.date.two.block.item.seven.title": 72,
      "bumblesite@rebrand.2024.page.date.two.block.item.six.text": 73,
      "bumblesite@rebrand.2024.page.date.two.block.item.six.title": 74,
      "bumblesite@rebrand.2024.page.date.two.block.item.three.text": 75,
      "bumblesite@rebrand.2024.page.date.two.block.item.three.title": 76,
      "bumblesite@rebrand.2024.page.date.two.block.item.two.text": 77,
      "bumblesite@rebrand.2024.page.date.two.block.item.two.title": 78,
      "bumblesite@rebrand.2024.page.date.two.block.title": 79,
      "bumblesite@rebrand.2024.page.index.four.block.item.one.text": 80,
      "bumblesite@rebrand.2024.page.index.four.block.item.one.title": 81,
      "bumblesite@rebrand.2024.page.index.four.block.item.three.text": 82,
      "bumblesite@rebrand.2024.page.index.four.block.item.three.title": 83,
      "bumblesite@rebrand.2024.page.index.four.block.item.two.text": 84,
      "bumblesite@rebrand.2024.page.index.four.block.item.two.title": 85,
      "bumblesite@rebrand.2024.page.index.four.block.title": 86,
      "bumblesite@rebrand.2024.page.index.hero.block.title": 87,
      "bumblesite@rebrand.2024.page.index.one.block.action": 88,
      "bumblesite@rebrand.2024.page.index.one.block.text": 89,
      "bumblesite@rebrand.2024.page.index.one.block.title": 90,
      "bumblesite@rebrand.2024.page.index.three.block.action": 91,
      "bumblesite@rebrand.2024.page.index.three.block.text": 92,
      "bumblesite@rebrand.2024.page.index.three.block.title": 93,
      "bumblesite@rebrand.2024.page.index.two.block.item.one.text": 94,
      "bumblesite@rebrand.2024.page.index.two.block.item.one.title": 95,
      "bumblesite@rebrand.2024.page.index.two.block.item.three.text": 96,
      "bumblesite@rebrand.2024.page.index.two.block.item.three.title": 97,
      "bumblesite@rebrand.2024.page.index.two.block.item.two.text": 98,
      "bumblesite@rebrand.2024.page.index.two.block.item.two.title": 99,
      "bumblesite@rebrand.2024.page.index.two.block.title": 100,
      "bumblesite@rebrand.2024.page.seo.text": 101,
      "bumblesite@social-links.item.a11y.label": 102,
      "bumblesite@team_bumble.page_index.footer.links.gender_gap": 103,
      "bumblesite@team_bumble.page_index.footer.links.slavery": 104,
      "bumblesite@team_bumble.page_index.footer.links.terms": 105,
      "bumblesite@team_bumble.page_index.navigation.links.about": 106,
      "bumblesite@team_bumble.page_index.navigation.links.life": 107,
      "bma_client@pages.error.not-found.description": 108,
      "bma_client@pages.faq.section.items.contact_us": 109,
      "bma_client@pages.login-password.submit": 110,
      "bma_client@video_chat.error_title": 111,
      "mw@link.a11y.label.new-window": 112,
      "bumble_mobile_web@company.link.faq": 113,
      "badoo_safety_center@footer.legal_links.cookie_policy": 114,
      "badoo_homepage@footer.blog": 115,
      "badoo_homepage@footer.careers": 116,
      "badoo_homepage@footer.chat": 117,
      "badoo_homepage@footer.cities": 118,
      "badoo_homepage@footer.cookies": 119,
      "badoo_homepage@footer.copyright": 120,
      "badoo_homepage@footer.dating_confidence_report": 121,
      "badoo_homepage@footer.download.copy.text": 122,
      "badoo_homepage@footer.download.title": 123,
      "badoo_homepage@footer.facebook": 124,
      "badoo_homepage@footer.follow_us.title": 125,
      "badoo_homepage@footer.help_centre": 126,
      "badoo_homepage@footer.instagram": 127,
      "badoo_homepage@footer.language.title": 128,
      "badoo_homepage@footer.linkedin": 129,
      "badoo_homepage@footer.part_of": 130,
      "badoo_homepage@footer.privacy": 131,
      "badoo_homepage@footer.safety_centre": 132,
      "badoo_homepage@footer.sitemap": 133,
      "badoo_homepage@footer.slavery_act": 134,
      "badoo_homepage@footer.terms": 135,
      "badoo_homepage@footer.twitter": 136
} as const;
