/* eslint-disable */
const localization = {
    // This is the array of lexemes found in the app
    lexemes: [
      "Voltar",
      "Baixe na App Store",
      "Baixe no Google Play",
      "Carrossel",
      "Slide {{number}}",
      "Próximo slide",
      "Slide anterior",
      "Slide",
      "Slide {{number}} de {{total}}",
      "Não vender nem compartilhar as minhas informações pessoais",
      "Não vender nem compartilhar as minhas informações pessoais",
      "Encerrar contratos aqui",
      "Página inicial do Bumble",
      "Fechar menu",
      "Abrir menu",
      "Selecionar idioma",
      "Rodapé",
      "Acessibilidade no Bumble",
      "A Apple e o logotipo da Apple são marcas comerciais da Apple Inc.",
      "Bumble BFF",
      "Bumble Bizz",
      "Bumble Date",
      "Vagas",
      "Eventos",
      "O Google Play é uma marca registrada da Google LLC.",
      "Regras",
      "Investidores",
      "Gerenciar Cookies",
      "Gerenciar Cookies",
      "Lei sobre Escravatura Moderna (Reino Unido)",
      "Notificação de coleta de dados",
      "Política de Privacidade",
      "Guias de encontros sazonais",
      "Termos e Condições",
      "App",
      "Política de privacidade de dados de saúde do consumidor de Washington",
      "Sobre",
      "Embaixadores",
      "O Buzz",
      "Loja",
      "Principal",
      "Ir para o conteúdo principal",
      "Logo do Bumble",
      "Aqui ser gentil é irresistível, todo mundo é perfeito do jeito que é e as mulheres vêm em primeiro lugar, sempre.",
      "É aqui que se Dá o Primeiro Passo™",
      "Relacionamentos saudáveis ​​contribuem para uma vida produtiva e positiva. O Bumble permite que você faça conexões das maneiras mais gratificantes, seja por meio de encontros, amizades ou oportunidades de carreira.",
      "O diferencial do Bumble",
      "Defendemos integridade, gentileza, igualdade, confiança e respeito em todas as fases de um relacionamento. Celebramos todas as orientações, quer você esteja aqui para encontrar o amor ou apenas para se divertir.",
      "Os valores do Bumble",
      "No Bumble são as mulheres que dão o Primeiro Passo ou escolhem uma Mensagem inicial para que as conexões possam responder. Decidimos sacudir o mundo do encontros e colocar a igualdade em primeiro lugar.",
      "Como o Bumble funciona",
      "Sobre nós",
      "Baixe agora",
      "É melhor no aplicativo.",
      "Recursos que tornam sua vida online muito mais agradável",
      "12 de fevereiro, 2024",
      "Como aumentar a intimidade emocional em um relacionamento",
      "25 de janeiro, 2024",
      "Como usar o Bumble quando você nunca usou um aplicativo de relacionamento antes",
      "5 de fevereiro, 2024",
      "Deception Detector™",
      "Todas as últimas notícias do mundo do encontros",
      "Navegue no <b>Para você</b> para ter perfis selecionados com base em suas preferências e conexões anteriores.",
      "Selecionados especialmente para você",
      "Reserve um momento para você com o <b>Modo Não Perturbe</b>. Quando quiser voltar, você sabe onde nos encontrar.",
      "Faça uma pausa",
      "Use as <b>chamadas de vídeo</b> e as <b>chamadas de áudio</b> para se aproximar das suas conexões sem sair do casa.",
      "Diga em voz alta",
      "Ver mais",
      "Escolha um <b>Opening Moves</b> para convidar sua conexão para iniciar uma conversa, para que você não precise fazer isso.",
      "Menos esforço, mais conexões",
      "Encontre sua felicidade em qualquer lugar do mundo graças ao <b>Modo Viagem</b>.",
      "Para onde vamos?",
      "Curtiu o perfil de alguém? Mostre enviando um <b>SuperSwipe</b>.",
      "Chame a atenção de alguém",
      "Ganhe destaque na multidão com <b>Compliments</b>.",
      "Dê um sorriso",
      "Esconda seu perfil de todo mundo com o <b>Modo Invisível</b>.",
      "Fique invisível",
      "Conheça pessoas do seu jeito",
      "Alguns meses depois, ela também conheceu uma de suas madrinhas lá",
      "Hannah conheceu seu atual marido no Bumble.",
      "Antes de construirem sua casa juntos, Carène e You-Liang se conheceram no Bumble, onde as fotos de viagem de Carène chamaram a atenção de You-Liang.",
      "Carène e You-Liang",
      "Breno e Karen trabalharam juntos no exterior, mas se conheceram no Bumble",
      "Breno e Karen",
      "Se funcionou para alguém, pode funcionar para você",
      "Dê o próximo passo",
      "Veja o que há de novo",
      "Você merece o melhor, por isso criamos ótimas maneiras para você namorar mais e se estressar menos.",
      "Pra que se contentar quando você pode ter mais?",
      "Sobre o Bumble",
      "Somos o único aplicativo que torna os encontros melhores, colocando as experiências das mulheres em primeiro lugar. Porque quando as coisas melhoram para as mulheres, as coisas melhoram para todos.",
      "Make the first move™",
      "Encontre alguém que você curta e dê o primeiro passo. Crie conexões genuínas, discuta interesses comuns e, acima de tudo, divirta-se.",
      "Encontre alguém com o Bumble Date",
      "Encontre amizades para te ajudar a progredir em sua carreira. Conecte-se com outros profissionais para encontrar seu próximo emprego, uma mentoria ou até mesmo uma nova carreira.",
      "Faça mudanças na carreira com o Bumble Bizz",
      "Conheça alguém que se pareça com você. Faça amizades e encontre atividades para fazerem juntos, na sua cidade ou no exterior.",
      "Encontre novas amizades com o Bumble For Friends",
      "Somos muito mais do que encontros",
      "O Bumble é o aplicativo de relacionamento onde as mulheres dão o primeiro passo. É o aplicativo que mudou a maneira como as pessoas se relacionam, criam conexões verdadeiras e fazem networking. Baixe agora.",
      "Bumble no {{social_network}}",
      "Gender Pay Gap",
      "Modern Slavery Statement",
      "Terms and Conditions",
      "About",
      "Life",
      "Página não encontrada.",
      "Contato",
      "Entrar",
      "Ops, algo deu errado!",
      "(abre em nova janela)",
      "FAQ",
      "Política de Cookies",
      "Blog do Bumble",
      "Vagas",
      "Conversas",
      "Cidades",
      "Gerenciar cookies",
      "Copyright © 2006 – presente. Bumble. Todos os direitos reservados.",
      "Relatório de Encontros com Confiança",
      "Apple e o logotipo da Apple são marcas comerciais da Apple Inc.{{__EOL__}}Google Play é uma marca registrada da Google LLC.",
      "Baixe o app",
      "Facebook",
      "Siga-nos",
      "Central de Ajuda",
      "Instagram",
      "Idioma",
      "LinkedIn",
      "O Bumble faz parte da Bumble Inc.",
      "Política de privacidade",
      "Safety Centre",
      "Mapa do site",
      "Declaração da Lei Modern Slavery Act",
      "Termos e condições",
      "Twitter"
],

    // These are the common words found in the lexemes of the app
    common: {
      "day": {
            "plural": [
                  "dias"
            ],
            "singular": [
                  "dia"
            ]
      },
      "hour": {
            "plural": [
                  "horas"
            ],
            "singular": [
                  "hora"
            ]
      },
      "minute": {
            "plural": [
                  "minutos"
            ],
            "singular": [
                  "minuto"
            ]
      },
      "month": {
            "plural": [
                  "meses"
            ],
            "singular": [
                  "mês"
            ]
      },
      "second": {
            "plural": [
                  "segundos"
            ],
            "singular": [
                  "segundo"
            ]
      },
      "year": {
            "plural": [
                  "anos"
            ],
            "singular": [
                  "ano"
            ]
      }
}
};

// In dev mode we extend lexemes to include the indexMap as well, in production it remains as an array
if (__DEV__) {
    // This is a map of lexeme ids to their index in the array, it's only needed during development
    const indexMap = require('./index-map').default;

    localization.indexMap = indexMap;

    Object.entries(localization.indexMap).forEach(([indexKey, indexValue]) => {
        localization.lexemes[indexKey] = localization.lexemes[indexValue];
    });
}

export default localization;
