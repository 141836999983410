/* eslint-disable */
const localization = {
    // This is the array of lexemes found in the app
    lexemes: [
      "Indietro",
      "Scarica da App Store",
      "Disponibile su Google Play",
      "Sezione",
      "Diapositiva {{number}}",
      "Prossima diapositiva",
      "Diapositiva precedente",
      "Diapositiva",
      "Diapositiva {{number}} di {{total}}",
      "Non vendere né condividere le mie informazioni personali",
      "Non vendere né condividere le mie informazioni personali",
      "Disdici i contratti qui",
      "Home page di Bumble",
      "Chiudi menu",
      "Apri menu",
      "Selezione lingua",
      "Piè di pagina",
      "Accessibilità a Bumble",
      "Apple e il logo di Apple sono marchi di Apple Inc.",
      "Bumble BFF",
      "Bumble Bizz",
      "Bumble",
      "Lavora con noi",
      "Eventi",
      "Google Play è un marchio di Google LLC.",
      "Linee Guida",
      "Investitori",
      "Preferenze cookie",
      "Preferenze cookie",
      "Dichiarazione sulle leggi contro la schiavitù moderna",
      "Informativa sulla raccolta dati",
      "Informativa sulla privacy",
      "Consigli aggiornati per conoscere gente",
      "Termini & Condizioni",
      "La app",
      "Informativa sulla privacy Washington Consumer Health",
      "Chi siamo",
      "Ambasciatori",
      "Blog",
      "Shop",
      "Principale",
      "Vai al contenuto principale",
      "Logo di Bumble",
      "Qui la gentilezza è sexy, essere sé stessi significa essere perfetti e le donne vengono prima di tutti. Sempre.",
      "È qui che si fa la prima mossa",
      "Delle relazioni sane sono cruciali per vivere una vita positiva e produttiva. Bumble è pensato per aiutarti a instaurare queste connessioni in piena sicurezza di te, che tu sia alla ricerca della persona speciale, di un'amicizia genuina o di espandere la tua rete professionale.",
      "Perché Bumble fa la differenza",
      "Promuoviamo integrità, gentilezza, uguaglianza, sicurezza e rispetto durante tutte le fasi di una relazione. E non ci limitiamo ad accogliere tutti gli orientamenti, ma li celebriamo sempre, sia per chi vuole trovare l'amore che per chi vuole semplicemente divertirsi.",
      "I valori di Bumble",
      "Su Bumble sono le donne a iniziare, facendo la prima mossa o scegliendo un'Opening Move a cui i collegamenti possono rispondere. Abbiamo deciso di dare un bella shakerata al mondo del dating e mettere l'uguaglianza in prima linea.",
      "Come funziona Bumble",
      "Chi siamo",
      "Scarica subito",
      "È meglio sull'app.",
      "Funzioni pensate per fare incontri online in modo piacevole, sul serio",
      "12 febbraio 2024",
      "Come avere più intimità emotiva in una relazione",
      "25 gennaio 2024",
      "Come usare Bumble se è la tua prima app di incontri",
      "5 febbraio 2024",
      "Deception Detector™",
      "Tutte le novità nel mondo del dating",
      "Esplora la sezione <b>Per te</b> e trova profili scelti in base alle tue preferenze e ai collegamenti già creati.",
      "Scelti proprio per te",
      "Prenditi un momento per te con la <b>modalità Snooze</b>. E quando vuoi tornare a conoscere gente, siamo qui ad aspettarti.",
      "Fai una pausa",
      "Fai <b>chiamate</b> e <b>videochiamate</b> per avere un assaggio dell'incontro reale, dal comfort di casa.",
      "A quattr'occhi (e orecchie)",
      "Vedi altri",
      "Scegli le <b>Opening Moves</b> per invitare i collegamenti ad avviare la chat, così non dovrai farlo tu.",
      "Meno da fare, più incontri",
      "Metti nuove conoscenze in valigia e trova gente in tutto il mondo con la <b>modalità Travel</b>.",
      "Dove si va?",
      "Il profilo si fa interessante? Faglielo sapere con un <b>SuperSwipe</b>.",
      "Cattura l'attenzione",
      "Invia dei <b>Compliments</b> per farti notare e distinguerti da tutti.",
      "Fai arrossire chi ti piace",
      "Usa la <b>modalità Incognito</b> per nascondere il tuo profilo da colleghi, parenti o anche ex.",
      "Vai sotto copertura",
      "Fai incontri a modo tuo",
      "Qualche mese dopo, ha anche conosciuto la ragazza che le ha fatto da damigella.",
      "Hannah ha conosciuto su Bumble l'uomo che ha poi sposato.",
      "Prima di costruire insieme la loro piccola e deliziosa casa, Carène e You-Liang si sono incontrati su Bumble. Sono state le foto dei viaggi di Carène a far scattare l'interesse.",
      "Carène e You-Liang",
      "Brian e Kaci lavoravano nella stessa città all'estero, ma si sono conosciuti su Bumble",
      "Brian e Kaci",
      "Se ha funzionato per loro, può funzionare per te",
      "Fai la prossima mossa",
      "Scopri le novità",
      "Ti meriti il meglio, per questo abbiamo pensato a ottimi modi per conoscere più gente ma con meno stress.",
      "Perché accontentarti quando puoi avere di più?",
      "Info su Bumble",
      "Siamo l'unica app che rende gli incontri online migliori mettendo al primo posto l'esperienza della donna. Perché se le cose vanno meglio per le donne, allora vanno meglio per tutti.",
      "Make the first move™",
      "Trova qualcuno che ti interessa davvero e fai la prima mossa. Crea collegamenti sinceri basati su interessi in comune e, soprattutto, divertiti.",
      "Conosci una persona speciale con Bumble Date",
      "Nuove conoscenze professionali per cercare il tuo prossimo lavoro, trovare qualcuno che possa farti da guida o anche cambiare completamente carriera.",
      "Coltiva la tua carriera con Bumble Bizz",
      "Trova qualcuno come te o l'esatto contrario. Crea nuove amicizie e scopri attività da fare insieme, sia a casa che in viaggio.",
      "Trova nuovi amici su Bumble For Friends",
      "Non solo incontri romantici",
      "Bumble è l'app di dating dove è la donna a fare la prima mossa. È sinonimo di cambiamento negli incontri online, sia in amore, in amicizia che nel networking. Scaricala subito.",
      "Bumble su {{social_network}}",
      "Gender Pay Gap",
      "Modern Slavery Statement",
      "Terms and Conditions",
      "About",
      "Life",
      "Siamo spiacenti, ma la pagina che cerchi non è stata trovata.",
      "Contattaci",
      "Accedi",
      "Ops… qualcosa è andato storto",
      "(opens in new window)",
      "Domande frequenti",
      "Cookie Policy",
      "Bumble - The Blog",
      "Lavora con noi",
      "Chat",
      "Città",
      "Preferenze cookie",
      "Copyright © 2006 – presente. Bumble. Tutti i diritti riservati.",
      "Appuntamenti in tutta tranquillità: report",
      "Apple e il logo di Apple sono marchi di Apple Inc.{{__EOL__}}Google Play è un marchio di Google LLC.",
      "Scarica",
      "Facebook",
      "Seguici",
      "AIuto",
      "Instagram",
      "Lingua",
      "LinkedIn",
      "Bumble fa parte di Bumble Inc.",
      "Informativa sulla privacy",
      "Safety Centre",
      "Mappa del sito",
      "Dichiarazione sulle leggi contro la schiavitù moderna",
      "Condizioni d'utilizzo",
      "Twitter"
],

    // These are the common words found in the lexemes of the app
    common: {
      "day": {
            "plural": [
                  "giorni"
            ],
            "singular": [
                  "giorno"
            ]
      },
      "hour": {
            "plural": [
                  "ore"
            ],
            "singular": [
                  "ora"
            ]
      },
      "minute": {
            "plural": [
                  "minuti"
            ],
            "singular": [
                  "minuto"
            ]
      },
      "month": {
            "plural": [
                  "mesi"
            ],
            "singular": [
                  "mese"
            ]
      },
      "second": {
            "plural": [
                  "secondi"
            ],
            "singular": [
                  "secondo"
            ]
      },
      "year": {
            "plural": [
                  "anni"
            ],
            "singular": [
                  "anno"
            ]
      }
}
};

// In dev mode we extend lexemes to include the indexMap as well, in production it remains as an array
if (__DEV__) {
    // This is a map of lexeme ids to their index in the array, it's only needed during development
    const indexMap = require('./index-map').default;

    localization.indexMap = indexMap;

    Object.entries(localization.indexMap).forEach(([indexKey, indexValue]) => {
        localization.lexemes[indexKey] = localization.lexemes[indexValue];
    });
}

export default localization;
