/* eslint-disable */
const localization = {
    // This is the array of lexemes found in the app
    lexemes: [
      "Tilbage",
      "Download på App Store",
      "Få den på Google Play",
      "Karrusel",
      "Slide {{number}}",
      "Næste slide",
      "Forrige slide",
      "Slide",
      "Slide {{number}} af {{total}}",
      "Sælg eller del ikke mine personlige oplysninger",
      "Sælg eller del ikke mine personlige informationer",
      "Opsig kontrakter her",
      "Bumble startside",
      "Luk menu",
      "Åbn menu",
      "Sprogvalg",
      "Footer",
      "Adgang til Bumble",
      "Apple og Apple-logoet er varemærker tilhørende Apple Inc.",
      "Bumble BFF",
      "Bumble Bizz",
      "Bumble Date",
      "Jobmuligheder",
      "Begivenheder",
      "Google Play er et varemærke tilhørende Google LLC.",
      "Retningslinjer",
      "Investorer",
      "Administrer cookies",
      "Administrer cookies",
      "Modern Slavery Act Statement",
      "Meddelelse ved indsamling",
      "Privatlivspolitik",
      "Sæsonbestemte datingguides",
      "Vilkår & betingelser",
      "Appen",
      "Privatlivspolitik for Consumer Health Data i Washington",
      "Om",
      "Ambassadører",
      "Blog",
      "Shoppen",
      "Primære",
      "Spring over til primært indhold",
      "Bumble-logo",
      "Her er det sexet at være venlig, her er du perfekt på din måde, og her kommer kvinder først. Altid.",
      "Stedet at tage det første skridt",
      "Sunde relationer er helt essentielle for at leve et positivt og produktivt liv. Bumble er designet til at hjælpe dig med at føle dig godt tilpas, mens du skaber dine forbindelser, uanset om du dater, leder efter venner eller udvider dit professionelle netværk.",
      "Sådan gør Bumble en forskel",
      "Vi går op i integritet, venlighed, lighed, selvsikkerhed og respekt på alle stadier af et forhold. Vi er ikke bare åbne overfor forskellige orienteringer, vi fejrer dem – uanset om du er her for at finde kærlighed eller bare vil have det sjovt.",
      "Bumbles værdier",
      "På Bumble er det kvinderne, der sætter tempoet ved at tage det første skridt eller oprette et Opening Move, som matches kan svare på. Vi bytter rundt på forældede kønsroller og sætter fokus på ligestilling fra begyndelsen.",
      "Hvordan fungerer Bumble",
      "Om os",
      "Download nu",
      "Det er bedre på appen.",
      "Funktioner som gør det sjovt og behageligt at date online",
      "12. februar 2024",
      "Sådan styrker I den følelsesmæssige intimitet i forholdet",
      "25. januar 2024",
      "Sådan bruger du Bumble, når du aldrig har brugt en datingapp før",
      "5. februar 2024",
      "Deception Detector™",
      "Interessante nyheder fra datingverdenen",
      "Browse <b>Til dig</b> for at se udvalgte profiler baseret dine præferencer og tidligere matches.",
      "Udvalgt til dig",
      "Sæt dating på pause med <b>Snooze-tilstand</b>. Når du er klar til at date igen, ved du, hvor vi er.",
      "Tag en pause",
      "Brug <b>videoopkald</b> og <b>audiochats</b> for at komme så tæt på IRL som muligt, uden at tage hjemmefra.",
      "Sæt lyd på",
      "Se mere",
      "Vælg <b>Opening Moves</b> for at invitere dit match til at starte chatten, så du ikke behøver at gøre det.",
      "Gør mindre, date mere",
      "Pak din kuffert, og match over hele verden med <b>Rejse-tilstand</b>.",
      "Tag os med",
      "Var du vild med profilen? Fortæl dem det med et <b>SuperSwipe</b>.",
      "Fang deres opmærksomhed",
      "Send nogle <b>komplimenter</b> til dem, så du skiller dig ud fra mængden.",
      "Få dem til at rødme",
      "Brug <b>Inkognito-tilstand</b> for at skjule din profil for din chef, familie eller ekskærester.",
      "Gå undercover",
      "Date på din måde",
      "Et par måneder senere mødte hun også en af sine brudepiger der.",
      "Hannah mødte sin mand på Bumble.",
      "Før de byggede deres tiny house sammen, mødtes Carène og You-Liang på Bumble, hvor You-Liang blev nysgerrig på Carènes rejsebilleder.",
      "Carène og You-Liang",
      "Brian og Kaci var udstationeret i udlandet sammen – men de mødte hinanden på Bumble",
      "Brian og Kaci",
      "Hvis det lykkedes for dem, kunne det også lykkes for dig",
      "Tag det næste skridt",
      "Se det nye",
      "Du fortjener det bedste. Derfor har vi designet fantastiske måder du kan date mere og stresse mindre på.",
      "Sæt barren tilbage til der, hvor den skal være",
      "Om Bumble",
      "Vi er den eneste app, der gør dating bedre ved at tage udgangspunkt i kvinders oplevelse. For når ting er bedre for kvinder, er de bedre for alle.",
      "Make the first move™",
      "Find en, du har lyst til at date, og tag så det første skridt. Skab stærke forbindelser, find det I har tilfælles, og – som det allervigtigste – hav det sjovt.",
      "Mød nogen med Bumble Date",
      "Find venner, der hjælper din karriere på vej. Mød andre professionelle for at finde dit næste job, en mentor eller en helt ny karriere.",
      "Giv din karriere et skub med Bumble Bizz",
      "Mød nogen ligesom dig. Eller helt anderledes end dig. Få nye venner, og find ting at lave sammen – uanset om det er ude eller hjemme.",
      "Find nye venner på Bumble For Friends",
      "Vi har ikke kun fokus på dating",
      "Bumble er datingappen, hvor kvinder tager det første skridt. Det er appen, der ændrede måden, som folk dater, skaber meningsfulde relationer og bygger netværk på. Download nu.",
      "Bumble på {{social_network}}",
      "Gender Pay Gap",
      "Modern Slavery Statement",
      "Terms and Conditions",
      "About",
      "Life",
      "Beklager, men vi kan ikke finde den side, du ledte efter.",
      "Kontakt us",
      "Log ind",
      "Hovsa, noget gik galt",
      "(opens in new window)",
      "FAQ",
      "Cookie Policy",
      "Bumble - Blog",
      "Jobmuligheder",
      "Chat",
      "Byer",
      "Administrer Cookies",
      "Copyright © 2006 – til nu. Bumble. Alle rettigheder forbeholdes.",
      "Rapport: Dating med tillid",
      "Apple og Apple-logoet er varemærker tilhørende Apple Inc.{{__EOL__}}Google Play er et varemærke tilhørende Google LLC.",
      "Download",
      "Facebook",
      "Følg os",
      "Help Center",
      "Instagram",
      "Sprog",
      "LinkedIn",
      "Bumble er en del af Inc.",
      "Privatlivspolitik",
      "Safety Centre",
      "Sitemap",
      "Modern Slavery Act statement",
      "Vilkår og betingelser",
      "Twitter"
],

    // These are the common words found in the lexemes of the app
    common: {
      "day": {
            "plural": [
                  "dage",
                  "dages"
            ],
            "singular": [
                  "dag",
                  "dags"
            ]
      },
      "hour": {
            "plural": [
                  "timer",
                  "timers"
            ],
            "singular": [
                  "time",
                  "times"
            ]
      },
      "minute": {
            "plural": [
                  "minutter",
                  "minutters"
            ],
            "singular": [
                  "minut",
                  "minuts"
            ]
      },
      "month": {
            "plural": [
                  "måneder",
                  "måneders"
            ],
            "singular": [
                  "måned",
                  "måneds"
            ]
      },
      "second": {
            "plural": [
                  "sekunder",
                  "sekunders"
            ],
            "singular": [
                  "sekund",
                  "sekunds"
            ]
      },
      "year": {
            "plural": [
                  "år",
                  "årenes"
            ],
            "singular": [
                  "år",
                  "årets"
            ]
      }
}
};

// In dev mode we extend lexemes to include the indexMap as well, in production it remains as an array
if (__DEV__) {
    // This is a map of lexeme ids to their index in the array, it's only needed during development
    const indexMap = require('./index-map').default;

    localization.indexMap = indexMap;

    Object.entries(localization.indexMap).forEach(([indexKey, indexValue]) => {
        localization.lexemes[indexKey] = localization.lexemes[indexValue];
    });
}

export default localization;
