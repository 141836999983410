/* eslint-disable */
const localization = {
    // This is the array of lexemes found in the app
    lexemes: [
      "वापस जाएँ",
      "App Store से डाउनलोड करें",
      "Google Play से प्राप्त करें",
      "कैरोसेल",
      "स्लाइड {{number}}",
      "अगली स्लाइड",
      "पिछली स्लाइड",
      "स्लाइड",
      "स्लाइड {{total}} में से {{number}}",
      "मेरी व्यक्तिगत जानकारी को न बेचें और न ही साझा करें",
      "मेरी निजी जानकारी न बेचें या न शेयर करें",
      "यहाँ कान्ट्रैक्ट समाप्त करें",
      "Bumble होमपेज",
      "मेनू बंद करें",
      "मेनू खोलें",
      "भाषा चुनें",
      "फूटर",
      "Bumble पर ऐक्सेसबिलिटी\n\n---",
      "Apple और Apple का लोगो Apple Inc के ट्रेडमार्क हैं।",
      "Bumble BFF",
      "Bumble Bizz",
      "Bumble डेट",
      "करियर",
      "इवेंट्स",
      "Google Play, Google LLC का ट्रेडमार्क है.",
      "दिशानिर्देश",
      "निवेशक",
      "कुकीज़ प्रबंधित करें",
      "कुकीज़ प्रबंधित करें",
      "Modern Slavery Act स्टेटमेंट",
      "संग्रह पर सूचना",
      "गोपनीयता नीति",
      "सीजनल डेटिंग गाइड्स",
      "नियम और शर्तें",
      "ऐप",
      "वाशिंगटन कंज्यूमर हैल्थ डाटा प्राइवेसी पॉलिसी",
      "हमारे बारे में",
      "एम्बेसडर",
      "बज्ज़",
      "शॉप",
      "मुख्य",
      "मुख्य सामग्री पर जाएं",
      "Bumble का लोगो",
      "यहां, दयालु होना सेक्सी है, आप परफेक्ट हैं, और महिलाएं हमेशा पहले आती हैं। हमेशा।",
      "'पहला कदम बढ़ाएं' का घर",
      "अच्छे संबंध एक सकारात्मक, उपयोगी ज़िंदगी के लिए महत्वपूर्ण हैं। Bumble को आपको कनेक्शन बनाने पर सशक्त महसूस करने में मदद करने के लिए बनाया गया है, चाहे आप डेटिंग कर रहे हों, दोस्तों को ढूँढ रहे हों, या अपना प्रोफेशनल नेटवर्क बढ़ा रहे हों।",
      "Bumble मायने क्यों रखता है",
      "हम किसी भी रिश्ते के सभी चरणों के दौरान सच्चाई, नेकी, समानता, आत्मविश्वास और सम्मान का समर्थन करते हैं। हम सिर्फ स्वागत नहीं करते, हम हर ओरीएन्टेशन का जश्न मनाते हैं—चाहे आप यहां प्यार ढूंढने आए हों या बस मनोरंजन ढूंढने आए हों।",
      "Bumble किसे महत्व देता है",
      "Bumble पर, महिलाएं पहले कदम बढ़ाकर या मैचों द्वारा जवाब देने के लिए Opening Move सेट करके माहौल तैयार करती हैं। हमारा मकसद पुराने जमाने की रूढ़िवादी सोच को बदलना और शुरू से ही समानता को प्रोत्साहित करना है।",
      "Bumble कैसे काम करता है",
      "हमारे बारे में",
      "अभी डाउनलोड करें",
      "यह ऐप पर बेहतर है।",
      "ऑनलाइन डेटिंग को वास्तव में आनंददायक बनाने के लिए डिज़ाइन किए गए फीचर",
      "फरवरी 12, 2024",
      "अपने रिश्ते में इमोशनल लगाव कैसे बढ़ाएं",
      "जनवरी 25, 2024",
      "जब आपने पहले कभी डेटिंग ऐप का उपयोग नहीं किया है तो Bumble का उपयोग कैसे करें",
      "फरवरी 5, 2024",
      "Deception Detector™",
      "सभी डेटिंग समाचार जो छापने योग्य हैं",
      "अपनी प्राथमिकताओं और पिछले मैचों के आधार पर चुने गए प्रोफ़ाइल प्राप्त करने के लिए <b>आपके लिए</b> को ब्राउज़ करें।",
      "आपके लिए चुने गए",
      "<b>स्नूज़ मोड</b> के साथ डेटिंग को होल्ड पर रखें। जब आप फिर से डेट करने के लिए तैयार हों, आपको पता ही है कि हम कहाँ हैं।",
      "एक ब्रेक लें",
      "अपना घर छोड़े बिना असल ज़िंदगी में करीब पहुंचने के लिए <b>वीडियो कॉल</b> और <b>ऑडियो चैट</b> का उपयोग करें।",
      "आवाज़ का जादू",
      "और देखें",
      "चैट शुरू करने के लिए अपने मैच को आमंत्रित करने के लिए <b>Opening Moves</b> चुनें, ताकि आपको ऐसा न करना पड़े।",
      "काम कम, डेट ज्यादा",
      "<b>ट्रैवल मोड</b> के साथ दुनिया में कहीं भी ड्यूटी-फ्री स्नैक और मैच ढूंढें।",
      "हमें ले चलें",
      "प्रोफ़ाइल वाइब जांच में पास हुई? <b>SuperSwipe</b> से उन्हें बताएं।",
      "उनका ध्यान आकर्षित करें",
      "उनकी कुछ <b>तारीफ</b> करें ताकि आप भीड़ से अलग दिखें।",
      "उन्हें शर्माने के लिए मजबूर करें",
      "अपने बॉस, रिश्तेदारों या पूर्व साथियों से अपनी प्रोफ़ाइल छिपाने के लिए <b>गुप्त मोड</b> का उपयोग करें।",
      "गुप्त हो जाएं",
      "जिस तरह से आप चाहें, उसी तरह डेट करें",
      "कुछ महीनों बाद, उसकी मुलाकात वहां उसकी ब्राइडमेडस से भी हुई",
      "हन्ना अपने वर्तमान पति से Bumble पर मिलीं।",
      "एक साथ अपना छोटा सा घर बनाने से पहले करीन और यू-लिआंग की मुलाकात Bumble पर हुई थी, जहां कैरेन की ट्रेवल की फ़ोटोज़ ने यू-लियांग को आकर्षित किया था।",
      "करीन और यू-लिआंग",
      "ब्रायन और केसी विदेश में एक साथ तैनात थे—लेकिन उनकी मुलाकात Bumble पर हुई",
      "ब्रायन और केसी",
      "यदि यह उनके लिए काम करता है, तो यह आपके लिए भी काम कर सकता है",
      "अगला कदम बढ़ाएं",
      "देखें कि नया क्या है",
      "आप बेहतर के हकदार हैं, इसलिए हमने आपके लिए अधिक डेट करने और तनाव कम करने के बेहतरीन तरीके डिज़ाइन किए हैं।",
      "अपने बार को वापस वहीं लाएँ जहाँ इसे होना चाहिए",
      "Bumble के बारे में",
      "हम एकमात्र ऐप हैं जो महिलाओं के अनुभवों को पहले स्थान पर रखकर डेटिंग को बेहतर बनाते हैं। क्योंकि जब चीजें महिलाओं के लिए बेहतर होती हैं, तो वे सभी के लिए बेहतर होती हैं।",
      "Make the first move™",
      "किसी ऐसे व्यक्ति को ढूंढें जिसे आप वास्तव में डेट करना चाहते हैं, फिर आगे बढ़ें और पहला कदम उठाएं। असल कनेक्शन बनाएं, वो करें जो आप दोनों को पसंद है और सबसे महत्वपूर्ण बात यह है कि मजे लें।",
      "Bumble Date पर किसी से मिलें",
      "ऐसे दोस्तों को ढूंढें जो कार्य क्षेत्र में आपकी मदद कर सकें। अपनी अगली नौकरी, गुरू या यहां तक कि एक नया करियर ढूंढने के लिए अन्य बिज़नस प्रोफेशनल्स से कनेक्ट करें।",
      "Bumble Bizz के साथ कैरियर को आगे बढ़ाएं",
      "अपने जैसे या अपने से विपरीत किसी व्यक्ति से मिलें। नए दोस्त बनाएं और साथ मिलकर करने के लिए नई चीजें खोजें - चाहे आप घर पर हों या बाहर।",
      "Bumble For Friends पर नये दोस्त बनाएं",
      "हम केवल डेटिंग के लिए नहीं हैं",
      "Bumble एक डेटिंग ऐप है जहां महिलाएं पहले कदम बढ़ाती हैं। यह वह ऐप है जिसने लोगों के डेट करने, सार्थक रिश्ते बनाने और नेटवर्क बनाने के तरीके को बदल दिया है। अब डाउनलोड करें।",
      "{{social_network}} पर Bumble",
      "Gender Pay Gap",
      "Modern Slavery Statement",
      "Terms and Conditions",
      "About",
      "Life",
      "हमें खेद है, पर आप जिस पेज को खोज रहे हैं हम उसे खोज नहीं सके हैं।",
      "हमसे संपर्क करें",
      "साइन इन करें",
      "अरे रे! कुछ गड़बड़ हो गई है",
      "(opens in new window)",
      "अक्सर पूछे जाने वाले सवाल",
      "Cookie Policy",
      "Bumble - The Blog",
      "नौकरी",
      "चैट",
      "शहर",
      "कुकी मैनेज करें",
      "Copyright © 2006 – present. Bumble. सभी अधिकार सुरक्षित हैं.",
      "डेटिंग कॉन्फ़िडेंस रिपोर्ट",
      "Apple और Apple का लोगो Apple Inc के ट्रेडमार्क हैं.{{__EOL__}}Google Play, Google LLC का ट्रेडमार्क है.",
      "डाउनलोड करें",
      "Facebook",
      "हमें फ़ॉलो करें",
      "सहायता केंद्र",
      "Instagram",
      "भाषा",
      "LinkedIn",
      "Bumble, Bumble Inc का हिस्सा है.",
      "निजता नीति",
      "सुरक्षा केंद्र",
      "साइटमैप",
      "मॉडर्न स्लेवरी ऐक्ट स्टेटमेंट",
      "नियम और शर्तें",
      "Twitter"
],

    // These are the common words found in the lexemes of the app
    common: {
      "day": {
            "plural": [
                  "दिन",
                  "दिनों"
            ],
            "singular": [
                  "दिन",
                  "दिन"
            ]
      },
      "hour": {
            "plural": [
                  "घंटे",
                  "घंटे"
            ],
            "singular": [
                  "घंटे",
                  "घंटे"
            ]
      },
      "minute": {
            "plural": [
                  "मिनट",
                  "मिनटों"
            ],
            "singular": [
                  "मिनट",
                  "मिनट"
            ]
      },
      "month": {
            "plural": [
                  "महीने",
                  "महीनों"
            ],
            "singular": [
                  "महीना",
                  "महीना"
            ]
      },
      "second": {
            "plural": [
                  "सेकंड",
                  "सेकंड"
            ],
            "singular": [
                  "सेकंड",
                  "सेकंड"
            ]
      },
      "year": {
            "plural": [
                  "साल",
                  "सालों"
            ],
            "singular": [
                  "साल",
                  "साल"
            ]
      }
}
};

// In dev mode we extend lexemes to include the indexMap as well, in production it remains as an array
if (__DEV__) {
    // This is a map of lexeme ids to their index in the array, it's only needed during development
    const indexMap = require('./index-map').default;

    localization.indexMap = indexMap;

    Object.entries(localization.indexMap).forEach(([indexKey, indexValue]) => {
        localization.lexemes[indexKey] = localization.lexemes[indexValue];
    });
}

export default localization;
