/* eslint-disable */
const localization = {
    // This is the array of lexemes found in the app
    lexemes: [
      "Annuleren",
      "Downloaden via de App Store",
      "Downloaden via Google Play",
      "Carrousel",
      "Slide {{number}}",
      "Volgende slide",
      "Vorige slide",
      "Slide",
      "Slide {{number}} van {{total}}",
      "Mijn persoonsgegevens niet verkopen of delen",
      "Mijn persoonsgegevens niet verkopen of delen",
      "Hier annuleren",
      "Homepage van Bumble",
      "Menu sluiten",
      "Menu openen",
      "Talenselectie",
      "Voettekst",
      "Toegankelijkheid bij Bumble",
      "Apple en het Apple-logo zijn handelsmerken van Apple Inc.",
      "Bumble BFF",
      "Bumble Bizz",
      "Bumble Date",
      "Carrière",
      "Evenementen",
      "Google Play is een handelsmerk van Google LLC.",
      "Richtlijnen",
      "Investeerders",
      "Cookies beheren",
      "Cookies beheren",
      "Verklaring Modern Slavery Act",
      "Bericht over gegevensverzameling",
      "Privacybeleid",
      "Datinggidsen per seizoen",
      "Algemene voorwaarden",
      "De app",
      "Privacybeleid gegevens consumentengezondheid Washington",
      "Over",
      "Ambassadeurs",
      "Blog",
      "De winkel",
      "Hoofdmenu",
      "Door naar hoofdpagina",
      "Logo van Bumble",
      "Hier is vriendelijk zijn sexy, jezelf zijn het beste en staan vrouwen altijd op de eerste plaats.",
      "Hier wordt de eerste stap gezet",
      "Gezonde relaties vormen een essentieel onderdeel van een goed leven. Bumble is ontwikkeld zodat jij vol vertrouwen nieuwe contacten kunt vinden, of je nu wilt daten, op zoek bent naar nieuwe vrienden of je professionele netwerk wilt uitbreiden.",
      "Wat Bumble bijdraagt",
      "Wij vinden integriteit, vriendelijkheid, gelijkheid, vertrouwen en respect tijdens alle fases van een relatie belangrijk. We verwelkomen niet alleen elke geaardheid, we vieren ze! Of je hier nu bent om liefde te vinden of gewoon wat plezier.",
      "Wat voor Bumble belangrijk is",
      "Op Bumble bepalen de vrouwen door de eerste stap te zetten of een Opening Move in te stellen waar matches op kunnen reageren. De ouderwetse dynamiek is verleden tijd. Nu gaan we vanaf het allereerste begin voor gelijkheid.",
      "Hoe Bumble werkt",
      "Over ons",
      "Nu downloaden",
      "Beter op de app.",
      "Functies om online daten weer leuk te maken",
      "12 feb. 2024",
      "De emotionele intimiteit in je relatie vergroten",
      "25 jan. 2024",
      "Bumble gebruiken als je nog nooit een datingapp hebt gebruikt",
      "5 feb. 2024",
      "Deception Detector™",
      "Al het datingnieuws dat je moet weten",
      "Kijk in <b>Voor jou</b> voor speciale profielen die zijn gebaseerd op je voorkeuren en eerdere matches.",
      "Voor jou gekozen",
      "Even pauze met <b>Snooze-modus</b>. Als je weer wilt daten, weet je ons te vinden.",
      "Even pauze",
      "Gebruik <b>videogesprekken</b> en <b>audiochats</b>. Zo is het bijna IRL, zonder dat je je huis uit hoeft.",
      "Gebruik eens geluid",
      "Meer bekijken",
      "Kies <b>Opening Moves</b> en nodig je match uit om de chat te beginnen.",
      "Minder doen, meer daten",
      "Vind je dutyfree snack en match overal ter wereld met <b>Reismodus</b>.",
      "Neem ons mee",
      "Heeft een profiel de juiste vibe? Laat het weten met een <b>SuperSwipe</b>.",
      "Trek iemands aandacht",
      "Geef de ander wat <b>Compliments</b> en val op tussen de rest.",
      "Een blos op de wangen",
      "Gebruik <b>Privémodus</b> om je profiel te verbergen voor je baas, familie of ex(en).",
      "Ga undercover",
      "Date hoe jij wilt",
      "Een paar maanden later ontmoette ze daar ook een van haar bruidsmeisjes.",
      "Hannah ontmoette haar man op Bumble",
      "Voordat ze samen hun tiny house zijn gaan bouwen, ontmoetten Carène en You-Liang elkaar op Bumble. You-Liang was totaal onder de indruk van de reisfoto's van Carène.",
      "Carène en You-Liang",
      "Brian en Kaci waren samen gestationeerd in het buitenland, maar ze ontmoetten elkaar op Bumble.",
      "Brian en Kaci",
      "Het werkte voor hen, dus waarom niet voor jou?",
      "Zet de volgende stap",
      "Bekijken wat nieuw is",
      "Jij verdient beter, dus we hebben leuke manieren ontworpen voor meer dates en minder stress.",
      "Leg de lat weer hoog",
      "Over Bumble",
      "Wij zijn de enige app waar daten een leukere ervaring wordt doordat vrouwen op de eerste plaats komen. Want als dingen beter zijn voor vrouwen, zijn dingen beter voor iedereen.",
      "Make the first move™",
      "Vind iemand waarmee je wilt daten en zet de eerste stap. Vind echte contacten, ontdek waar jullie van houden en vergeet niet om plezier te hebben.",
      "Ontmoet iemand met Bumble Date",
      "Friends with 'benefits', maar dan voor op de werkvloer. Kom in contact met andere professionals en vind je volgende baan, een mentor of misschien wel een hele nieuwe carrière.",
      "Zet stappen in je carrière met Bumble Bizz",
      "Ontmoet iemand net als jij. Of juist niet zoals jij. Maak nieuwe vrienden en ontdek nieuwe dingen om samen te doen, of dat nu thuis is of ergens anders.",
      "Vind nieuwe vrienden op Bumble For Friends",
      "Zoveel meer dan dating",
      "Bumble is de datingapp waar vrouwen de eerste stap zetten. Het is de app die heeft gezorgd voor een verandering in de manier waarop mensen daten, waarbij waardevolle relaties en netwerkcontacten zich ontwikkelen. Download nu.",
      "Bumble op {{social_network}}",
      "Gender Pay Gap",
      "Modern Slavery Statement",
      "Terms and Conditions",
      "About",
      "Life",
      "Helaas is de pagina die je zoekt niet te vinden",
      "Contact",
      "Inloggen",
      "Oeps, er ging iets fout",
      "(opens in new window)",
      "Veelgestelde vragen",
      "Cookie Policy",
      "Bumble – Blog",
      "Vacatures",
      "Chatten",
      "Steden",
      "Cookies beheren",
      "Copyright © 2006 – heden. Bumble. Alle rechten voorbehouden.",
      "Rapport Vertrouwen in daten",
      "Apple en het Apple-logo zijn handelsmerken van Apple Inc.{{__EOL__}}Google Play is een handelsmerk van Google LLC.",
      "Downloaden",
      "Facebook",
      "Volg ons",
      "Hulppagina",
      "Instagram",
      "Taal",
      "LinkedIn",
      "Bumble is onderdeel van Bumble Inc.",
      "Privacybeleid",
      "Safety centre",
      "Sitemap",
      "Verklaring inzake moderne slavernij",
      "Algemene voorwaarden",
      "Twitter"
],

    // These are the common words found in the lexemes of the app
    common: {
      "day": {
            "plural": [
                  "dagen"
            ],
            "singular": [
                  "dag"
            ]
      },
      "hour": {
            "plural": [
                  "uur"
            ],
            "singular": [
                  "uur"
            ]
      },
      "minute": {
            "plural": [
                  "minuten"
            ],
            "singular": [
                  "minuut"
            ]
      },
      "month": {
            "plural": [
                  "maanden"
            ],
            "singular": [
                  "maand"
            ]
      },
      "second": {
            "plural": [
                  "seconden"
            ],
            "singular": [
                  "seconde"
            ]
      },
      "year": {
            "plural": [
                  "jaar"
            ],
            "singular": [
                  "jaar"
            ]
      }
}
};

// In dev mode we extend lexemes to include the indexMap as well, in production it remains as an array
if (__DEV__) {
    // This is a map of lexeme ids to their index in the array, it's only needed during development
    const indexMap = require('./index-map').default;

    localization.indexMap = indexMap;

    Object.entries(localization.indexMap).forEach(([indexKey, indexValue]) => {
        localization.lexemes[indexKey] = localization.lexemes[indexValue];
    });
}

export default localization;
