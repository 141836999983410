import React, { FunctionComponent } from 'react';

import { pageIndex } from 'components/lexemes';
import { CdnImagePath } from 'lib/cdn-image-path';

import { onClickAboutCTA } from 'containers/events';

import PageSectionCta from 'components/page/sections/page-section-cta';

export const LandingBlockThreeContainer: FunctionComponent = () => {
    const props = {
        imagePosition: 'left',
        imageSrc: `${CdnImagePath}/landing/assets/photos/landing-section-3.jpg`,
        imageObjectPosition: '33% 31%',
        imageAlt: '',
        title: pageIndex.lxPageIndexThreeBlockTitle,
        text: pageIndex.lxPageIndexThreeBlockText,
        actionText: pageIndex.lxPageIndexThreeBlockAction,
        actionUrl: 'https://bumble.com/about',
    };

    return (
        <PageSectionCta
            title={props.title}
            /**
             * Fix language based code
             */
            titleLang={'en'}
            text={props.text}
            action={{
                text: props.actionText,
                href: props.actionUrl,
                onClick: onClickAboutCTA,
            }}
            image={{
                src: props.imageSrc,
                objectPosition: props.imageObjectPosition,
                inlinePosition: 'start',
            }}
            color="yellow"
        />
    );
};
