/* eslint-disable */
const localization = {
    // This is the array of lexemes found in the app
    lexemes: [
      "돌아가기",
      "App Store에서 다운받기",
      "Google Play에서 다운받기",
      "캐러셀",
      "슬라이드 {{number}}",
      "다음 슬라이드",
      "이전 슬라이드",
      "슬라이드",
      "슬라이드 {{total}} 중 {{number}}",
      "내 개인정보를 판매하거나 공유하지 않음",
      "내 개인정보를 판매하거나 공유하지 않음",
      "이곳에서 계약을 종료하세요",
      "Bumble 홈페이지",
      "메뉴 닫기",
      "메뉴 열기",
      "언어 옵션",
      "꼬리말",
      "Bumble 에서의 접근성",
      "Apple 및 Apple 로고는 Apple Inc.의 등록 상표입니다.",
      "Bumble BFF",
      "Bumble Bizz",
      "Bumble Date",
      "인재채용",
      "이벤트",
      "Google Play는 Google LLC의 상표입니다.",
      "가이드라인",
      "투자자",
      "쿠키 관리",
      "쿠키 관리",
      "현대판 노예 방지 성명",
      "수집 시 통지",
      "개인정보 취급방침",
      "시즌 별 데이트 가이드",
      "이용약관",
      "앱",
      "Washington 소비자 건강 데이터 개인 정보 보호 정책",
      "소개",
      "앰배서더",
      "블로그",
      "상점",
      "메인",
      "메인 페이지로 건너뛰기",
      "Bumble 로고",
      "이곳에서는 친절함이 곧 섹시함이며, 여러분 스스로의 가장 나 다운 모습이 완벽함이며, 여성이 먼저입니다. 항상요.",
      "첫인사를 건네는 곳의 시작",
      "긍정적이고 생산적인 삶의 핵심은 건강한 관계로부터 시작합니다. Bumble은 통제력을 지닌 상태에서 데이트 상대를 찾고 계시든, 친구를 찾고 계시든, 커리어 기반의 네트워크를 확장하고 싶으시든 상관없이 이러한 관계를 구축하시도록 도와드릴 수 있게 고안되었습니다.",
      "Bumble 이 중요한 이유",
      "저희는 관계의 모든 단계에 진실함, 친절함, 평등, 그리고 존경을 중심으로 행동하도록 격려하고 있습니다. 저희는 사랑을 찾고 있거나 단순히 재미있는 시간을 찾고 있는 모든 사람들을 환영하는 것 뿐만 아니라 모든 성향을 찬양하고자 합니다.",
      "Bumble 의 가치",
      "Bumble 에서는 여성들이 먼저 첫인사를 건네거나 짝들이 답장을 보낼 수 있는 Opening Move를 설정할 수 있습니다. 저희는 구식의 생각을 전환하여 인연의 첫 단계부터 평등이 유지될 수 있도록 격려하고 있습니다.",
      "Bumble 사용 방법",
      "저희를 소개합니다",
      "지금 다운로드하기",
      "앱은 더 멋져요.",
      "온라인 데이팅을 진심으로 즐겁게 만들기 위해 디자인된 기능들",
      "2024년 2월 12일",
      "관계에서 감정적 친밀감을 높이는 방법",
      "2024년 1월 25일",
      "데이팅 앱을 사용해본 적이 없으신 분에게 Bumble 앱을 사용하는 방법",
      "2024년 2월 5일",
      "사기 탐지기™",
      "널리 읽혀야 할 모든 데이팅 뉴스",
      "<b>추천</b> 을 둘러보면 회원님의 선호사항과 이전의 짝들에 기초하여 추천되는 프로필들을 볼 수 있어요.",
      "회원님을 위해 엄선되었어요",
      "<b>스누즈 모드</b> 로 데이팅을 일시정지하세요. 다시 데이트할 준비가 되면, 언제든지 돌아오세요.",
      "잠깐 휴식하기",
      "<b>영상 통화</b> 와 <b>오디오 채팅</b> 을 사용해서 집콕하면서 상대방을 만나세요.",
      "다양하게 소통하세요",
      "더 보기",
      "<b>Opening Moves</b> 를 선택하면 내가 먼저 대화를 시작할 필요 없이 짝들이 인사를 건네도록 초대할 수 있어요.",
      "더 적은 노력으로 더 많이 데이트하세요",
      "<b>트래블 모드</b> 로 전 세계 어느 곳에서 짝이 될 수 있어요.",
      "가고 싶은 곳으로 떠나세요",
      "상대방의 프로필 분위기가 합격이라구요? <b>SuperSwipe</b> 로 마음을 전하세요.",
      "상대방의 주목을 끄세요",
      "<b>Compliments</b> 를 보내서 사람들 속에서 돋보이세요.",
      "상대방의 기분을 띄워주세요",
      "<b>투명 회원 모드</b> 를 사용해서 전 연인, 친척, 직장 상사로부터 프로필을 숨기세요.",
      "프라이버시를 지키세요",
      "원하는 방식으로 데이트하세요",
      "몇달 후에, 신부 들러리 중 한 명도 그곳에서 만나셨어요.",
      "Hannah님은 지금 남편을 Bumble에서 만나셨어요.",
      "이제 함께 작은 집을 짓고 있는 Carène님과 You-Liang님은 Bumble에서 만났어요. You-Liang님이 Carène님의 여행 사진에 끌렸다고 해요.",
      "Carène님과 You-Liang님",
      "Brian님과 Kaci님은 함께 외국에 발령을 받았어요 - 하지만 두 분은 Bumble에서 만나셨어요",
      "Brian님과 Kaci님",
      "다른 사람들에게 일어난 일은 여러분에게도 일어날 수 있어요",
      "다음 인연을 만드세요",
      "업데이트를 살펴보세요",
      "회원님은 최고로 대접받을 가치가 있어요. 그래서 스트레스 없이 데이트를 즐길 수 있는 멋진 방법들을 고안해봤어요.",
      "이상형의 기준을 타협할 필요가 없어요",
      "Bumble 소개",
      "저희는 여성의 경험을 우선시하여 데이팅 경험을 보다 낫게 만드는 단 하나 뿐인 앱입니다. 여성에게 나은 경험이 모두에게 나은 경험이기 때문입니다.",
      "Make the first move™",
      "정말로 데이트하고 싶은 사람을 찾고, 첫인사를 먼저 건네세요. 진실한 연결을 만들고, 공통 관심사를 나누세요. 그리고 가장 중요한 것은, 즐거운 시간을 보내세요.",
      "Bumble Date로 누군가를 만나세요",
      "커리어 혜택을 가진 친구들을 찾으세요. 다음 직장, 멘토, 혹은 완전히 새로운 커리어를 찾기 위해 다른 비지니스 전문가들과 연결되세요.",
      "Bumble Bizz로 커리어를 성장시키세요",
      "나와 닮은 사람을 만나세요. 아니면 반대인 사람을요. 집이든 여행 중이든 함께 새로운 활동을 할 수 있는 새로운 친구들을 만드세요.",
      "Bumble For Friends에서 새로운 친구들을 찾으세요",
      "데이팅 그 이상을 제공합니다",
      "Bumble은 여성이 첫 인사를 건네는 데이팅 앱입니다. 데이트와 의미있는 관계를 형성하고 네트워크를 만드는 방식을 변화시킨 앱입니다. 지금 다운로드하세요.",
      "{{social_network}} 에서의 Bumble",
      "Gender Pay Gap",
      "Modern Slavery Statement",
      "Terms and Conditions",
      "About",
      "Life",
      "죄송합니다. 현재 찾으시는 페이지를 발견할 수 없습니다.",
      "문의하기",
      "로그인하기",
      "앗, 오류가 발생했어요",
      "(opens in new window)",
      "자주 하는 질문",
      "Cookie Policy",
      "Bumble - The Blog",
      "인재 채용",
      "채팅",
      "시",
      "쿠키 관리",
      "Copyright © 2006 – present. Bumble. All rights reserved.",
      "자신 있는 데이트를 위한 보고서",
      "Apple 및 Apple 로고는 Apple의 등록 상표입니다.{{__EOL__}}Google Play(은)는 Google LLC의 상표입니다.",
      "다운로드하세요",
      "Facebook",
      "팔로우하세요",
      "도움말 센터",
      "Instagram",
      "언어",
      "LinkedIn",
      "Bumble는 Bumble Inc의 일부입니다.",
      "개인정보 취급방침",
      "Safety Centre",
      "사이트맵",
      "현대판 노예제 방지 성명",
      "이용약관",
      "Twitter"
],

    // These are the common words found in the lexemes of the app
    common: {
      "day": {
            "plural": [
                  "일"
            ],
            "singular": [
                  "일"
            ]
      },
      "hour": {
            "plural": [
                  "시간"
            ],
            "singular": [
                  "시간"
            ]
      },
      "minute": {
            "plural": [
                  "분"
            ],
            "singular": [
                  "분"
            ]
      },
      "month": {
            "plural": [
                  "개월"
            ],
            "singular": [
                  "개월"
            ]
      },
      "second": {
            "plural": [
                  "초"
            ],
            "singular": [
                  "초"
            ]
      },
      "year": {
            "plural": [
                  "년"
            ],
            "singular": [
                  "년"
            ]
      }
}
};

// In dev mode we extend lexemes to include the indexMap as well, in production it remains as an array
if (__DEV__) {
    // This is a map of lexeme ids to their index in the array, it's only needed during development
    const indexMap = require('./index-map').default;

    localization.indexMap = indexMap;

    Object.entries(localization.indexMap).forEach(([indexKey, indexValue]) => {
        localization.lexemes[indexKey] = localization.lexemes[indexValue];
    });
}

export default localization;
