/* eslint-disable */
const localization = {
    // This is the array of lexemes found in the app
    lexemes: [
      "Mene takaisin",
      "Lataa App Storesta",
      "Hanki se Google Playsta",
      "Karuselli",
      "Kuva {{number}}",
      "Seuraava dia",
      "Edellinen dia",
      "Kuvasarja",
      "Kuva {{number}} kautta {{total}}",
      "Älä myy tai jaa henkilökohtaisia tietojani",
      "Älä myy tai jaa henkilökohtaisia tietojani",
      "Päätä sopimukset tästä",
      "Bumblen kotisivu",
      "Sulje valikko",
      "Avaa valikko",
      "Kielivalinnat",
      "Alatunniste",
      "Saavutettavuus Bumblessa",
      "Apple ja Apple-logo ovat Apple Inc:n tavaramerkkejä.",
      "Bumble BFF",
      "Bumble Bizz",
      "Bumble Date",
      "Työpaikat",
      "Tapahtumat",
      "Google Play on Google LLC:n tavaramerkki.",
      "Käyttöohjeet",
      "Sijoittajat",
      "Hallinnoi evästeitä",
      "Hallinnoi evästeitä",
      "Lausunto nykyajan orjuudesta",
      "Tietojen kerääminen",
      "Tietosuoja",
      "Kausiluontoisia deittioppaita",
      "Käyttöehdot",
      "Appi",
      "Kuluttajien terveystietojen tietosuoja Washingtonissa",
      "Yleistä",
      "Tuote-edustajat",
      "Buzz",
      "Kauppa",
      "Päävalikko",
      "Palaa kotisivulle",
      "Bumblen logo",
      "Täällä ystävällisyys ja aitous ovat etusijalla, ja naiset otetaan huomioon.",
      "Ensimmäisen siirron tekeminen sai alkunsa täältä",
      "Hyvät ihmissuhteet ovat keskeinen osa elämää. Bumble on sosiaalinen verkosto, jonka kautta voit luoda arvokkaita ihmissuhteita seurustelu-, kaveri- tai uramielessä. Ensimmäinen siirto Bumblessa voi muuttaa koko elämäsi.",
      "Miksi Bumble on merkittävä",
      "Rehellisyys, kiltteys, tasa-arvo, itsevarmuus ja kunnioitus ovat suhteen perusta. Me juhlistamme kaikkia suuntaumuksia – olitpa sitten etsimässä rakkautta tai pitämässä hauskaa.",
      "Bumble-arvot",
      "Bumblessa naiset tekevät ensimmäisen siirron tai asettavat Opening Moven muiden vastattavaksi. Vanhanaikaiset suhdedynamiikat joutavat meillä romukoppaan tasa-arvon tieltä.",
      "Miten Bumble toimii",
      "Tietoa meistä",
      "Lataa nyt",
      "Sovelluksessa sujuu paremmin.",
      "Ominaisuutemme tekevät nettideittailusta oikeasti kivaa",
      "12. helmikuuta 2024",
      "Näin voit lisätä emotionaalista läheisyyttä suhteessasi",
      "25. tammikuuta 2024",
      "Ovatko deittisovellukset vieraita? Lue Bumble-vinkkimme",
      "5. helmikuuta 2024",
      "Deception Detector™",
      "Nämä deittiuutiset sopisivat lehteenkin",
      "Selaa <b>Sinulle</b>-osiota saadaksesi juuri sinulle sopivia suosituksia.",
      "Sinulle sopivat tyypit",
      "<b>Poissaolotilalla</b> voit pitää taukoa deittailusta. Kun olet taas valmis deittailemaan, tiedät mistä meidät löytää.",
      "Pidä tauko",
      "<b>Videopuheluilla</b> ja <b>äänipuheluilla</b> pääset lähemmäs aitoa yhteyttä kotisi rauhasta.",
      "Sano se ääneen",
      "Katso lisää",
      "<b>Opening Movesin</b> avulla yhteytesi voi aloittaa keskustelun, jotta sinun ei tarvitse.",
      "Vaivatonta deittailua",
      "<b>Matkatilassa</b> löydät verottomien herkkujen lisäksi yhteyksiä maailmalta.",
      "Reissun päälle",
      "Tekikö profiili vaikutuksen? Kerro se <b>SuperSwipellä</b>.",
      "Herätä huomiota",
      "Erotu joukosta lähettämällä <b>Compliments</b> eli kehu.",
      "Ilahduta kehuin",
      "Piilota profiilisi pomoltasi, sukulaisiltasi tai exiltäsi <b>Incognito-tilalla</b>.",
      "Pysy piilossa",
      "Deittaile omalla tavallasi",
      "Muutamaa kuukautta myöhemmin hän tapasi yhden morsiusneidoistaankin sovelluksessa",
      "Hanna tapasi miehensä Bumblessa.",
      "Ennen kotinsa rakentamista Carène ja You-Liang tapasivat Bumblessa, jossa Carènen lomakuvat vetivät You-Liangia puoleensa.",
      "Carène ja You-Liang",
      "Brian ja Kaci olivat molemmat ulkomailla armeijan hommissa, mutta tapasivat Bumblessa.",
      "Brian ja Kaci",
      "Sinä voi olla seuraava onnenpekka",
      "Tee seuraava siirto",
      "Katso, mitä uutta tarjoamme",
      "Ansaitset parempaa, joten olemme suunnitelleet ominaisuuksia, joiden avulla deittailet enemmän ja stressaat vähemmän.",
      "Nosta ansaitusti rimaa",
      "Tietoa Bumblesta",
      "Olemme ainoa deittisovellus, jossa naisten kokemus on etusijalla. Koska naiset viihtyvät, niin viihtyvät muutkin.",
      "Make the first move™",
      "Etsi sinua miellyttävä tyyppi ja tee ensimmäinen siirto. Luo merkityksellisiä yhteyksiä, juttele harrastuksistasi ja pidä hauskaa.",
      "Tapaa se oikea Bumble Datessa",
      "Löydä kavereita tai töitä. Luo yhteys muihin ammattilaisiin löytääksesi töitä, mentorin tai kokonaan uuden uran.",
      "Tee urasiirtoja Bumble Bizzillä.",
      "Tapaa kaltaisesi. Tai joku erilainen. Ystävysty ja tee jotain uutta, olitpa sitten kotona tai maailmalla.",
      "Löydä uusia ystäviä Bumble For Friendsissä",
      "Deittailua ja paljon muutakin",
      "Bumblessa naiset tekevät ensimmäisen siirron. Sovelluksemme muutti deittailun maailmaa, ja nyt meillä luodaan merkityksellisiä suhteita ja verkostoja. Lataa nyt.",
      "Bumble {{social_network}} alustalla",
      "Gender Pay Gap",
      "Modern Slavery Statement",
      "Terms and Conditions",
      "About",
      "Life",
      "Valitettavasti etsimääsi sivua ei löytynyt.",
      "Yhteystiedot",
      "Kirjaudu sisään",
      "No voi, jotain on vialla",
      "(opens in new window)",
      "UKK",
      "Cookie Policy",
      "Bumble - The Blog",
      "Työt",
      "Chattaa",
      "Kaupunkeja",
      "Hallinnoi evästeitä",
      "Copyright © 2006 – tähän päivään. Bumble. Kaikki oikeudet pidätetään.",
      "Deittailuraportti",
      "Apple ja Apple-logo ovat Apple Inc:n tavaramerkkejä.{{__EOL__}}Google Play on Google LLC:n tavaramerkki.",
      "Lataa",
      "Facebook",
      "Seuraa meitä",
      "Asiakastuki",
      "Instagram",
      "Kieli",
      "LinkedIn",
      "Bumble-palvelun tarjoaa Bumble Inc.",
      "Tietosuoja",
      "Turvallisuuskeskus",
      "Sivustokartta",
      "Lausunto nykyajan orjuudesta",
      "Käyttöehdot",
      "Twitter"
],

    // These are the common words found in the lexemes of the app
    common: {
      "day": {
            "plural": [
                  "päivää",
                  "päivää",
                  "päivää",
                  "päivää",
                  "päivää",
                  "päivää",
                  "päivää",
                  "päivää",
                  "päivää",
                  "päivää",
                  "päivää",
                  "päivää",
                  "päivää",
                  "päivää",
                  "päivää"
            ],
            "singular": [
                  "päivä",
                  "päivä",
                  "päivä",
                  "päivä",
                  "päivä",
                  "päivä",
                  "päivä",
                  "päivä",
                  "päivä",
                  "päivä",
                  "päivä",
                  "päivä",
                  "päivä",
                  "päivä",
                  "päivä"
            ]
      },
      "hour": {
            "plural": [
                  "tuntia",
                  "tuntia",
                  "tunnin",
                  "tuntia",
                  "tuntia",
                  "tuntia",
                  "tuntia",
                  "tuntia",
                  "tuntia",
                  "tuntia",
                  "tuntia",
                  "tuntia",
                  "tuntia",
                  "tuntia",
                  "tuntia"
            ],
            "singular": [
                  "tunti",
                  "tunti",
                  "tunnin",
                  "tunti",
                  "tunti",
                  "tunti",
                  "tunti",
                  "tunti",
                  "tunti",
                  "tunti",
                  "tunti",
                  "tunti",
                  "tunti",
                  "tunti",
                  "tunti"
            ]
      },
      "minute": {
            "plural": [
                  "minuuttia",
                  "minuuttia",
                  "minuutin",
                  "minuuttia",
                  "minuuttia",
                  "minuuttia",
                  "minuuttia",
                  "minuuttia",
                  "minuuttia",
                  "minuuttia",
                  "minuuttia",
                  "minuuttia",
                  "minuuttia",
                  "minuuttia",
                  "minuuttia"
            ],
            "singular": [
                  "minuutti",
                  "minuutti",
                  "minuutin",
                  "minuutti",
                  "minuutti",
                  "minuutti",
                  "minuutti",
                  "minuutti",
                  "minuutti",
                  "minuutti",
                  "minuutti",
                  "minuutti",
                  "minuutti",
                  "minuutti",
                  "minuutti"
            ]
      },
      "month": {
            "plural": [
                  "kuukautta",
                  "kuukautta",
                  "kuukautta",
                  "kuukautta",
                  "kuukautta",
                  "kuukautta",
                  "kuukautta",
                  "kuukautta",
                  "kuukautta",
                  "kuukautta",
                  "kuukautta",
                  "kuukautta",
                  "kuukautta",
                  "kuukautta",
                  "kuukautta"
            ],
            "singular": [
                  "kuukausi",
                  "kuukausi",
                  "kuukausi",
                  "kuukausi",
                  "kuukausi",
                  "kuukausi",
                  "kuukausi",
                  "kuukausi",
                  "kuukausi",
                  "kuukausi",
                  "kuukausi",
                  "kuukausi",
                  "kuukausi",
                  "kuukausi",
                  "kuukausi"
            ]
      },
      "second": {
            "plural": [
                  "sekuntia",
                  "sekuntia",
                  "sekuntia",
                  "sekuntia",
                  "sekuntia",
                  "sekuntia",
                  "sekuntia",
                  "sekuntia",
                  "sekuntia",
                  "sekuntia",
                  "sekuntia",
                  "sekuntia",
                  "sekuntia",
                  "sekuntia",
                  "sekuntia"
            ],
            "singular": [
                  "sekunti",
                  "sekunti",
                  "sekunti",
                  "sekunti",
                  "sekunti",
                  "sekunti",
                  "sekunti",
                  "sekunti",
                  "sekunti",
                  "sekunti",
                  "sekunti",
                  "sekunti",
                  "sekunti",
                  "sekunti",
                  "sekunti"
            ]
      },
      "year": {
            "plural": [
                  "vuotta",
                  "vuotta",
                  "vuotta",
                  "vuotta",
                  "vuotta",
                  "vuotta",
                  "vuotta",
                  "vuotta",
                  "vuotta",
                  "vuotta",
                  "vuotta",
                  "vuotta",
                  "vuotta",
                  "vuotta",
                  "vuotta"
            ],
            "singular": [
                  "vuosi",
                  "vuosi",
                  "vuosi",
                  "vuosi",
                  "vuosi",
                  "vuosi",
                  "vuosi",
                  "vuosi",
                  "vuosi",
                  "vuosi",
                  "vuosi",
                  "vuosi",
                  "vuosi",
                  "vuosi",
                  "vuosi"
            ]
      }
}
};

// In dev mode we extend lexemes to include the indexMap as well, in production it remains as an array
if (__DEV__) {
    // This is a map of lexeme ids to their index in the array, it's only needed during development
    const indexMap = require('./index-map').default;

    localization.indexMap = indexMap;

    Object.entries(localization.indexMap).forEach(([indexKey, indexValue]) => {
        localization.lexemes[indexKey] = localization.lexemes[indexValue];
    });
}

export default localization;
